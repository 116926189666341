import dynamic from 'next/dynamic';

const pages = {
  "home.HomePage": dynamic(() => import("./HomePage")),
  "flex.FlexPage": dynamic(() => import("./FlexPage")),
  "contentHub.ArticlePage": dynamic(() => import("./ArticlePage")),
  "contentHub.ArticleListingPage": dynamic(() => import("./ArticleListingPage")),
  // NotFoundPage: dynamic(() => import("./NotFoundPage")),
  PasswordProtectedPage: dynamic(() => import("./PasswordProtectedPage")),
  'forms.FormPage': dynamic(() => import('./FormPage')),
};

export type Pages = keyof typeof pages;

export default pages;
